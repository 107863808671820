import {
  Cell,
  BitBuilder,
  BitReader,
  beginCell,
  Slice,
  Builder,
  Dictionary,
} from "@ton/ton";
import { jsonIpfs } from "../ipfs";
import { sha256 } from "@ton/crypto";

const OFFCHAIN_CONTENT_PREFIX = 0x01;

export function encodeOffChainContent(content: string) {
  let data = Buffer.from(content);
  let offChainPrefix = Buffer.from([OFFCHAIN_CONTENT_PREFIX]);
  data = Buffer.concat([offChainPrefix, data]);
  return makeSnakeCell(data);
}

export function decodeOffChainContent(content: Cell) {
  let data = flattenSnakeCell(content);

  let prefix = data[0];
  if (prefix !== OFFCHAIN_CONTENT_PREFIX) {
    throw new Error(`Unknown content prefix: ${prefix.toString(16)}`);
  }
  return data.subarray(1).toString();
}

export function makeSnakeCell(data: Buffer): Cell {
  const chunks = bufferToChunks(data, 127);

  if (chunks.length === 0) {
    return beginCell().endCell();
  }

  if (chunks.length === 1) {
    return beginCell().storeBuffer(chunks[0]).endCell();
  }

  let curCell = beginCell();

  for (let i = chunks.length - 1; i >= 0; i--) {
    const chunk = chunks[i];

    curCell.storeBuffer(chunk);

    if (i - 1 >= 0) {
      const nextCell = beginCell();
      nextCell.storeRef(curCell);
      curCell = nextCell;
    }
  }

  return curCell.endCell();
}

export function flattenSnakeCell(cell: Cell): Buffer {
  let c: Cell | null = cell;

  const bitResult = new BitBuilder();
  while (c) {
    const cs = c.beginParse();
    if (cs.remainingBits === 0) {
      break;
    }

    const data = cs.loadBits(cs.remainingBits);
    bitResult.writeBits(data);
    c = c.refs && c.refs[0];
  }

  const endBits = bitResult.build();
  const reader = new BitReader(endBits);

  return reader.loadBuffer(reader.remaining / 8);
}

function bufferToChunks(buff: Buffer, chunkSize: number) {
  let chunks: Buffer[] = [];
  while (buff.byteLength > 0) {
    chunks.push(buff.subarray(0, chunkSize));
    buff = buff.subarray(chunkSize);
  }

  return chunks;
}

interface ChunkDictValue {
  content: Buffer;
}

interface NFTDictValue {
  content: Buffer;
}

export function ParseChunkDict(cell: Slice): Buffer {
  const dict = cell.loadDict(
    Dictionary.Keys.Uint(32),
    ChunkDictValueSerializer
  );

  let buf = Buffer.alloc(0);
  for (const [_, v] of dict) {
    buf = Buffer.concat([buf, v.content]);
  }
  return buf;
}

export const ChunkDictValueSerializer = {
  serialize(src: ChunkDictValue, builder: Builder) {},
  parse(src: Slice): ChunkDictValue {
    const snake = flattenSnakeCell(src.loadRef());
    return { content: snake };
  },
};

export const NFTDictValueSerializer = {
  serialize(src: NFTDictValue, builder: Builder) {},
  parse(src: Slice): NFTDictValue {
    const ref = src.loadRef().asSlice();

    const start = ref.loadUint(8);
    if (start === 0) {
      const snake = flattenSnakeCell(ref.asCell());
      return { content: snake };
    }

    if (start === 1) {
      return { content: ParseChunkDict(ref) };
    }

    return { content: Buffer.from([]) };
  },
};

export async function decodeContentNFT(nft, dataCell: Cell) {
  const data = dataCell.asSlice();
  const start = data.loadUint(8);
  if (start !== 0) {
    // json offchain

    const link = decodeOffChainContent(dataCell);
    if (link.startsWith("ipfs")) {
      try {
        const json = await jsonIpfs(link);
        return {
          ...json,
          owner: nft.owner,
          collection: nft.collection,
          metadata: "ipfs",
          edition: nft.index,
        };
      } catch (ex) {
        return {
          name: "",
          description: "",
          image: "",
          owner: nft.owner,
          collection: nft.collection,
          metadata: "ipfs",
          edition: nft.index,
        };
      }
    } else {
      try {
        return await fetch(link)
          .then((response) => response.json())
          .then((json) => {
            return {
              ...json,
              owner: nft.owner,
              collection: nft.collection,
              metadata: "Centralize",
              edition: nft.index,
            };
          });
      } catch (ex) {
        return;
      }
    }
  } else {
    // data onchain
    const dict = data.loadDict(
      Dictionary.Keys.Buffer(32),
      NFTDictValueSerializer
    );

    const keys = ["image", "name", "description"];
    const obj = {};
    for (const key of keys) {
      const dictKey = await sha256(key);
      const dictValue = dict.get(dictKey);
      if (dictValue) {
        obj[key] = dictValue.content.toString("utf-8");
      } else {
        obj[key] = "";
      }
    }
    obj["owner"] = nft.owning;
    obj["collection"] = nft.collection;
    obj["edition"] = nft.index;
    return obj;
  }
}

export async function decodeContentCollect(dataCell: Cell) {
  const data = dataCell.asSlice();
  const start = data.loadUint(8);
  if (start !== 0) {
    // json offchain

    const link = decodeOffChainContent(dataCell);
    if (link.startsWith("ipfs")) {
      const json = await jsonIpfs(link);

      return {
        ...json,
      };
    } else {
      return await fetch(link)
        .then((response) => response.json())
        .then((json) => {
          return {
            ...json,
          };
        });
    }
  } else {
    // data onchain
    const dict = data.loadDict(
      Dictionary.Keys.Buffer(32),
      NFTDictValueSerializer
    );

    const keys = ["image", "name", "description"];
    const obj = {};
    for (const key of keys) {
      const dictKey = await sha256(key);
      const dictValue = dict.get(dictKey);
      if (dictValue) {
        obj[key] = dictValue.content.toString("utf-8");
      } else {
        obj[key] = "";
      }
    }

    return obj;
  }
}
