import { Address, TonClient } from "@ton/ton";
import { sleep } from "./wait";
export async function makeGetCallV2<T>(
  address: Address | undefined,
  name: string,
  params: any[],
  tonClient: TonClient
) {
  if (!address) {
    throw new Error("Address must be defined");
  }
  try {
    const { stack } = await tonClient.runMethod(address, name, params);
    return stack;
  } catch (ex) {
    await sleep(100);
    const { stack } = await tonClient.runMethod(address, name, params);
    return stack;
  }
}
