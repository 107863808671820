import axios from "@/services/axiosNftConfig";
import { Result } from "@/models/Result";
import { errorCodes } from "@/utils/errorCode";
import { useTelegram } from "@/hooks/useTelegram";
import { useAlertStore } from "@/store";
const { showAlert } = useTelegram();
export function nftCaller(
  method,
  apiPath: string,
  jsonData: any,
  headers: any = {},
  showErrorNotify: boolean = true
) {
  const axiosConfig = {
    method: method,
    data: jsonData,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
  };

  const useAlert = useAlertStore();

  return axios(apiPath, axiosConfig)
    .then(async (response) => {
      const data = response.data as Result;

      switch (data.statusCode) {
        case errorCodes.accepted:
        case errorCodes.success:
          break;
        case errorCodes.internalServerError:
          if (showErrorNotify) {
            useAlert.$patch({
              alert: true,
              message: "Error 500",
              type: "error",
            });
          }
          break;
        default:
          if (showErrorNotify) {
            useAlert.$patch({
              alert: true,
              message: data.message,
              type: "error",
            });
          }
          break;
      }
      return data;
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const result = error.response.data as Result;

        if (showErrorNotify) {
          if (error.response.status == 500) {
            useAlert.$patch({
              alert: true,
              message: "Error 500",
              type: "error",
            });
          } else {
            useAlert.$patch({
              alert: true,
              message: result.message
                ? result.message
                : error.response.data.message,
              type: "error",
            });
          }
          return null;
        } else {
          return result;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        if (!error.response) {
          notifyNetworkError(error.message);
        } else {
          useAlert.$patch({
            alert: true,
            message: error.request.toString(),
            type: "error",
          });
        }
        return null;
      } else {
        // Something happened in setting up the request that triggered an Error

        showAlert(error.message);

        useAlert.$patch({
          alert: true,
          message: error.message,
          type: "error",
        });
        return null;
      }
    });
}

/**
 * Gom thông báo network error gửi 1 lần thay vì mỗi request mỗi notify
 */
let timeToNotifiy = 1;
let timeoutNoitfy = null as any;
function notifyNetworkError(message) {
  const useAlert = useAlertStore();
  if (timeoutNoitfy == null) {
    timeoutNoitfy = setInterval(() => {
      if (timeToNotifiy > 0) timeToNotifiy -= 1;
      if (timeToNotifiy == 0) {
        useAlert.$patch({
          alert: true,
          message: message,
          type: "error",
        });
        timeToNotifiy = 1;
        clearInterval(timeoutNoitfy);
        timeoutNoitfy = null;
      }
    }, 1000);
  }
}
