import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app",
  ref: "app"
};
import { computed, watch } from "vue";
import { useRoute } from "vue-router";
export default {
  __name: 'App',
  setup(__props) {
    const route = useRoute();
    const pageName = computed(() => route.meta.pageName);
    watch(pageName, () => {
      if (pageName.value) {
        if (route.params && route.params.id) {
          document.title = pageName.value + " / " + route.params.id;
        } else {
          document.title = pageName.value;
        }
      }
    }, {
      immediate: true
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view)], 512);
    };
  }
};