import { TonClient } from "@ton/ton";
import { getHttpEndpoint } from "@orbs-network/ton-access";
import { PARAMSV2 } from "../constantsV2";

const endpointPV4 = getHttpEndpoint({
  network: "mainnet",
});

async function _getClientV4() {
  const arr = PARAMSV2.apiKeyAuction;
  const key = arr[Math.floor(Math.random() * arr.length)];
  return new TonClient({
    endpoint: PARAMSV2.apiUrl,
    apiKey: key,
  });
}

const clientPV4 = _getClientV4();

export async function getClientV4() {
  return clientPV4;
}

export async function getEndpointV4() {
  return endpointPV4;
}
