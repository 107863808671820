import { Address, Cell, beginCell, fromNano, toNano } from "@ton/core";
import { getClientV2 } from "./useClientV2";
import { makeGetCallV2 } from "./make-get-call-v2";
import { toUserFriendlyAddress } from "@tonconnect/sdk";
import { ParseChunkDict, flattenSnakeCell, parseIcoMetadata } from "./parse";
import { _parseGetMethodCall } from "./make-get-call";

export async function getAirDropConfig(contract: string) {
  const contractAddr = Address.parse(contract);
  const tc = await getClientV2();
  const stack = await makeGetCallV2(contractAddr, "get_config", [], tc);
  const config = {
    owner: toUserFriendlyAddress(stack.readAddress().toRawString()),
    startTime: stack.readNumber(),
    isPaused: stack.readBoolean(),
    totalUsers: stack.readNumber(),
    totalAllocation: fromNano(stack.readNumber()),
    merkleRoot: stack.readBigNumber().toString(16),
    helperCode: stack.readCell().toBoc().toString("hex"),
    admin: toUserFriendlyAddress(stack.readAddress().toRawString()),
  };

  return config;
}

export async function getAirDropJettonConfig(contract: string) {
  const contractAddr = Address.parse(contract);
  const tc = await getClientV2();
  const stack = await makeGetCallV2(contractAddr, "get_jetton_config", [], tc);
  const config = {
    jettonMinter: toUserFriendlyAddress(stack.readAddress().toRawString()),
    jettonWallet: toUserFriendlyAddress(stack.readAddress().toRawString()),
  };
  return config;
}

export async function getJettonData(token: string) {
  const tokenAddr = Address.parse(token);
  const tc = await getClientV2();

  const stack = await makeGetCallV2(tokenAddr, "get_jetton_data", [], tc);

  const data = {
    supply: stack.readNumber(),
    mintable: stack.readBoolean(),
    admin: stack.readAddress(),
  };
  return data;
}

export async function withdrawJetton(contract: string, amount: number) {
  const contractAddr = Address.parse(contract);
  const tc = await getClientV2();
  const result = await makeGetCallV2(
    contractAddr,
    "withdraw_jetton",
    [
      {
        type: "slice",
        cell: beginCell()
          .storeUint(15, 32)
          .storeCoins(toNano(amount))
          .endCell(),
      },
    ],
    tc
  );

  return result;
}

export async function seqno(address: string) {
  const userAddress = Address.parse(address);
  const tc = await getClientV2();
  const result = await makeGetCallV2(userAddress, "seqno", [], tc);

  return result.readNumber();
}

export async function withdrawTon(contract: string, amount: number) {
  const contractAddr = Address.parse(contract);
  const tc = await getClientV2();
  const result = await makeGetCallV2(
    contractAddr,
    "withdraw_ton",
    [
      {
        type: "slice",
        cell: beginCell()
          .storeUint(14, 32)
          .storeCoins(toNano(amount))
          .endCell(),
      },
    ],
    tc
  );

  return result;
}

export async function getProgress(contract: string) {
  const contractAddr = Address.parse(contract);
  const tc = await getClientV2();
  const stack = await makeGetCallV2(contractAddr, "get_progress", [], tc);

  const progress = stack.readNumber();
  return Number(fromNano(progress));
}

export async function getAirdropProgress(contract: string) {
  const contractAddr = Address.parse(contract);
  const tc = await getClientV2();
  const stack = await makeGetCallV2(contractAddr, "get_progress", [], tc);
  const totalClaim = stack.readNumber();
  const progress = stack.readNumber();
  const accumulatedSysFee = stack.readNumber();
  return {
    totalClaimedUsers: Number(totalClaim),
    progress: Number(fromNano(progress)),
    accumulatedSysFee: Number(fromNano(accumulatedSysFee)),
  };
}

export async function getTotalParticipants(contract: string) {
  const contractAddr = Address.parse(contract);
  const tc = await getClientV2();
  const stack = await makeGetCallV2(
    contractAddr,
    "get_total_participants",
    [],
    tc
  );

  const total = stack.readNumber();
  return total;
}

export async function getParticipants(contract: string, userAddress: string) {
  const userAddr = Address.parse(userAddress);
  const contractAddr = Address.parse(contract);
  const tc = await getClientV2();
  const stack = await makeGetCallV2(
    contractAddr,
    "get_participant",
    [
      {
        type: "slice",
        cell: beginCell().storeAddress(userAddr).endCell(),
      },
    ],
    tc
  );
  const part = {
    buy_sofar: Number(fromNano(stack.readNumber())),
    claimed_amount: Number(fromNano(stack.readNumber())),
    claimable_amount: Number(fromNano(stack.readNumber())),
  };

  return part;
}

export async function getIsFinalized(contract: string) {
  const contractAddr = Address.parse(contract);
  const tc = await getClientV2();
  const stack = await makeGetCallV2(contractAddr, "get_is_finalized", [], tc);
  const isFinalized = stack.readBoolean();
  console.log("isFinalized", isFinalized);
  return isFinalized;
}

export async function getWalletAddress(owner: string, token: string) {
  const ownerAddr = Address.parse(owner);
  const tokenAddr = Address.parse(token);
  const tc = await getClientV2();
  const jWalletAddress = await makeGetCallV2(
    tokenAddr,
    "get_wallet_address",
    [
      {
        type: "slice",
        cell: beginCell().storeAddress(ownerAddr).endCell(),
      },
    ],
    tc
  );
  return jWalletAddress.readAddress();
}

export async function getWalletData(owner: string, token: string) {
  const jWalletAddress = await getWalletAddress(owner, token);
  const tc = await getClientV2();
  const stack = await makeGetCallV2(jWalletAddress, "get_wallet_data", [], tc);
  const walletData = {
    balance: Number(fromNano(stack.readNumber())),
    jWalletAddress: stack.readAddress(),
    jettonMasterAddress: stack.readAddress(),
  };

  return walletData;
}
