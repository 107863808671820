import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-455bdcb4"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["value"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _directive_number = _resolveDirective("number");
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "text",
    autocomplete: "off",
    value: _ctx.maskedValue,
    class: "number-format",
    onChange: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.change && _ctx.change(...args)),
    onInput: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.input && _ctx.input(...args)),
    onBlur: _cache[2] || (_cache[2] =
    //@ts-ignore
    (...args) => _ctx.blur && _ctx.blur(...args)),
    onKeyup: _cache[3] || (_cache[3] =
    //@ts-ignore
    (...args) => _ctx.change && _ctx.change(...args)),
    onKeypress: _cache[4] || (_cache[4] =
    //@ts-ignore
    (...args) => _ctx.onKeypress && _ctx.onKeypress(...args)),
    onFocus: _cache[5] || (_cache[5] = $event => _ctx.$emit('focus', _ctx.$el)),
    inputmode: "decimal",
    placeholder: "0",
    ref: "inputRef"
  }, null, 40, _hoisted_1)), [[_directive_number, _ctx.config]]);
}