import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9f962e4e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "Alert"
};
const _hoisted_2 = {
  class: "Alert-panel"
};
const _hoisted_3 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_Transition, {
    name: "AlertTransition",
    appear: ""
  }, {
    default: _withCtx(() => [_createElementVNode("aside", _hoisted_1, [_createElementVNode("div", {
      class: "Alert-overlay",
      onClick: _cache[0] || (_cache[0] =
      //@ts-ignore
      (...args) => _ctx.close && _ctx.close(...args))
    }), _createElementVNode("div", _hoisted_2, [_createElementVNode("h2", null, _toDisplayString(_ctx.title), 1), _createElementVNode("p", null, _toDisplayString(_ctx.message), 1), _ctx.link && _ctx.linkText ? (_openBlock(), _createElementBlock("a", {
      key: 0,
      href: _ctx.link,
      target: "_blank",
      class: "Alert-link"
    }, _toDisplayString(_ctx.linkText), 9, _hoisted_3)) : _createCommentVNode("", true), _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] =
      //@ts-ignore
      (...args) => _ctx.close && _ctx.close(...args))
    }, _toDisplayString(_ctx.buttonLabel), 1)])])]),
    _: 1
  });
}