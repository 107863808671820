export const PARAMSV2 = {
  // toncenter client key
  apiKey: "1e38ccc42264ca031561799eca6db8ce12bededfdcc26d15d8dcadd1da485076",
  apiKeyBE: "78410808b09ab9c7cb1f09c5088c5e0e1ab5b6d4f7b916cafc3f7a3ed4f57bc0",
  apiKeyAuction: [
    "a04fe0c657fb24a26854a0ab4436a16cf7515d51a1787fcd2bb6003f6631963b",
    "cdbb6ad5ddb6721cac6251ee5791bbc746df065982b8f905abf4dd2baccb8c1e",
  ],
  // key for call api /v1/account/connect
  apiKeyConnect: process.env.VUE_APP_APIKEY_CONNECT,
  apiUrl: "https://toncenter.com/api/v2/jsonRPC",
  manifestUrl: "https://app.defihub.network/tonconnect-manifest.json",
  gas: 0.06, // gas ico
  telegramMint: "t.me/DefiApp_bot/mintnft",
  telegramMarketPlace: "t.me/DefiApp_bot/marketplace",
  telegramProfile: "t.me/DefiApp_bot/profile",
  telegramWebUrl: "t.me/DefiApp_bot/app",
  telegramConnect: "t.me/DefiApp_bot/connect",
  telegramGame: "t.me/DefiApp_bot/game",
  telegramLaunchpad: "t.me/DefiApp_bot/launchpad",
  telegramAirdrop: "t.me/DefiApp_bot/distribution",
  telegramImport: "t.me/DefiApp_bot/import",
  telegramWebReturn: "https://t.me/DefiApp_bot/checkout",
  telegramWebReturnConnect: "t.me/DefiApp_bot/checkout",
  prefixOrder: "pay_orderId_",
  prefixCheckOrder: "check_orderId_",
  paymentValidUntil: 300,
  defaultAsset: [
    {
      asset: "LMI",
      symbol: "LMI",
      name: "LMI",
      address: "EQB_PFeQ1bJQt6noVVuHhmYcF0fiAX7ecF1lLURBiaZ5I4qh",
      image: "/token/lmi.png",
    },
    {
      asset: "wPOP",
      symbol: "wPOP",
      name: "WRAPPED POPLAUNCH",
      address: "EQA2oA71r4YBkQzVNdpYWyrxKeOLbTN1aldh7qtlzBvcojah",
      image: "/token/wpop.webp",
    },
    {
      asset: "wCATCC",
      symbol: "wCATCC",
      name: "WRAPPED CATCC",
      address: "EQA3qJKUqGFzHce6k_oe-6cPLLZuQ2gBepHSm11Khjc2kSh2",
      image: "/token/catcc.webp",
    },
  ],
  tonViewer: "https://tonscan.org/address/",
  nftServiceFee: 0.05, // 5% defihub, x% royalty, y% owner after sale success
};
