import { defineComponent, ref, computed, watch, onBeforeUnmount } from "vue";
import { cloneDeep } from "@/utils/format/core";
import NumberFormat from "@/utils/format/number-format";
import directive from "@/utils/format/directive";
import defaultOptions from "@/utils/format/options";
import { getInputSelection, setInputSelection, transformTypedChar } from "@/utils/format/parse";
const options = cloneDeep(defaultOptions);
export default defineComponent({
  name: "VueNumber",
  directives: {
    number: directive
  },
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
      default: 0
    },
    nullValue: {
      type: [Number, String],
      default: options.nullValue
    },
    masked: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    reverseFill: {
      type: Boolean,
      default: options.reverseFill
    },
    prefill: {
      type: Boolean,
      default: options.prefill
    },
    precision: {
      type: Number,
      default: () => options.precision
    },
    minimumFractionDigits: {
      type: Number,
      default: () => options.minimumFractionDigits
    },
    decimal: {
      type: String,
      default: () => options.decimal
    },
    min: {
      type: Number,
      default: () => options.min
    },
    max: {
      type: Number,
      default: () => options.max
    },
    separator: {
      type: String,
      default: () => options.separator
    },
    prefix: {
      type: String,
      default: () => options.prefix
    },
    suffix: {
      type: String,
      default: () => options.suffix
    }
  },
  emits: ["update:model-value", "input:model-value", "focus"],
  setup(props, {
    emit
  }) {
    const maskedValue = ref(props.modelValue);
    const canEmit = ref(false);
    const unmaskedValue = ref(props.modelValue);
    const config = computed(() => ({
      decimal: ".",
      separator: ",",
      prefix: "",
      suffix: "",
      precision: 9,
      nullValue: "",
      masked: false,
      reverseFill: false,
      max: 9999999999999
    }));
    const formatNumber = new NumberFormat(config.value);
    const emittedValue = computed(() => {
      if (props.masked) {
        return formatNumber.format(maskedValue.value);
      }
      return unmaskedValue.value;
    });
    const input = event => {
      const {
        target
      } = event;
      maskedValue.value = target.value;
      unmaskedValue.value = target.unmasked;
      canEmit.value = true;
      emit("input:model-value", emittedValue.value);
    };
    const change = () => {
      setTimeout(() => {
        emit("update:model-value", emittedValue.value);
      });
    };
    const blur = () => {
      if (canEmit.value && emittedValue.value !== props.modelValue) {
        change();
      }
      console.log("blur");
    };
    watch(() => props.modelValue, newValue => {
      const number = formatNumber.format(newValue);
      if (number !== maskedValue.value) {
        maskedValue.value = number;
      }
    });
    const onKeypress = evt => {
      if (evt.which) {
        var charStr = String.fromCharCode(evt.which);
        var transformedChar = transformTypedChar(charStr);
        if (transformedChar != charStr) {
          evt.preventDefault();
          var sel = getInputSelection(evt.target),
            val = evt.target.value;
          evt.target.value = val.slice(0, sel.start) + transformedChar + val.slice(sel.end);
          // Move the caret
          setInputSelection(evt.target, sel.start + 1, sel.start + 1);
          return false;
        }
      }
    };
    const inputRef = ref(null);
    onBeforeUnmount(() => {
      if (inputRef.value) {
        inputRef.value.blur();
      }
    });
    return {
      config,
      maskedValue,
      unmaskedValue,
      input,
      blur,
      change,
      onKeypress,
      inputRef
    };
  }
});