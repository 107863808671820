import { NFTCollection } from "@/models/NFTCollection";
import { NFTProcssModel } from "@/models/NFTProcssModel";
import { defineStore } from "pinia";
import { DefaultStorage } from "@/utils/localstorage";
const storage = new DefaultStorage();
export const useAirdropStore = defineStore("airdrop", {
  state: () => ({ wallet: "" }),
  actions: {
    getWallet() {
      this.wallet;
    },
  },
});

export const useCollectStore = defineStore("collection", {
  state: () => ({ collect: new NFTCollection() }),
  actions: {
    getCollect() {
      this.collect;
    },
  },
});

export const useMintStore = defineStore("mint", {
  state: () => ({ mint: false }),
  actions: {
    getMint() {
      this.mint;
    },
  },
});

async function getLocalStorage() {
  const stored = await storage.getItem("process-nft");
  if (stored) {
    try {
      return JSON.parse(stored);
    } catch (ex) {
      return new Array<NFTProcssModel>();
    }
  } else return new Array<NFTProcssModel>();
}

export const useNftProcessStore = defineStore("nftprocess", {
  state: () => ({ nfts: new Array<NFTProcssModel>() }),

  actions: {
    async getLocal() {
      this.nfts = await getLocalStorage();
    },
    async add(payload: NFTProcssModel) {
      const index = this.nfts.findIndex(
        (item) => item.onchainId == payload.onchainId
      );

      if (index == -1) {
        this.nfts.push(payload);
        await storage.setItem("process-nft", JSON.stringify(this.nfts));
      }
    },
    async remove(onchainId) {
      const index = this.nfts.findIndex((item) => item.onchainId == onchainId);
      if (index !== -1) {
        this.nfts.splice(index, 1);

        await storage.setItem("process-nft", JSON.stringify(this.nfts));
      }
    },
    async removeAll() {
      await storage.removeItem("process-nft");
      this.nfts = new Array<NFTProcssModel>();
    },
  },
});

export const useAlertStore = defineStore("alert", {
  state: () => ({ alert: false, message: "", type: "" as string }),
});
