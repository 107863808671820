import { defineComponent } from "vue";
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    buttonLabel: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: false
    },
    linkText: {
      type: String,
      required: false
    }
  },
  created() {
    document.addEventListener("keydown", this.onKeyDown);
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  methods: {
    onKeyDown(e) {
      if (e.keyCode === 27) {
        this.close();
      }
    },
    close() {
      this.$emit("close");
    }
  }
});