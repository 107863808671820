import "element-plus/dist/index.css";
import "@/assets/styles/index.scss";
import { useTelegram } from "@/hooks/useTelegram";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { createApp } from "vue";
import UUID from "vue-uuid";
import VueLazyLoad from "vue3-lazyload";
import App from "./App.vue";
import i18n from "./i18n";
import router from "./router";
import { createPinia } from "pinia";
import { getCSSVariable } from "./utils/dom";
import { darkenColor } from "./utils/color";

import { SnackbarService } from "vue3-snackbar";
import "vue3-snackbar/styles";

const { platform, ready, showAlert } = useTelegram();

if (platform !== "unknown") {
  switch (platform) {
    case "android":
    case "android_x":
    case "web":
    case "weba":
    case "tdesktop":
      document.body.classList.add("is-material");
      break;
    case "ios":
    case "macos":
      document.body.classList.add("is-apple");
      break;
    default:
      document.body.classList.add(`is-${platform}`);
      break;
  }
}

/**
 * Some clients may use material/apple base styles, but has some overrides
 * For instance, WebK uses material but more rounded and clean
 */
document.body.classList.add(`is-exact-${platform}`);

/**
 * In the last Telegram iOS client, some theme variable are broken in Dark mode:
 * --tg-theme-bg-color (used for island in docs) became #000000
 * --tg-theme-secondary-bg-color (used for app bg in docs) became lighter that the --tg-theme-bg-color
 *
 * As a temporary workaround, we check if the variables are broken and swap them
 *
 * Another issue we have in iOS Dark Dimmed theme: both variables are the same, so we manually change one of them
 *
 */
function handleBrokenVariables(): void {
  const themeBgColor = getCSSVariable("--tg-theme-bg-color");
  const themeSecondaryBgColor = getCSSVariable("--tg-theme-secondary-bg-color");

  if (themeBgColor === "#1c1c1d" && themeSecondaryBgColor !== "#1c1c1d") {
    document.documentElement.style.setProperty(
      "--tg-theme-bg-color",
      themeSecondaryBgColor ?? ""
    );
    document.documentElement.style.setProperty(
      "--tg-theme-secondary-bg-color",
      themeBgColor ?? ""
    );

    return;
  }

  /**
   * Workaround problem with iOS Dark Dimmed theme. Manually make secondary bg color darker
   */
  if (themeBgColor === themeSecondaryBgColor && themeBgColor !== undefined) {
    document.documentElement.style.setProperty(
      "--tg-theme-secondary-bg-color",
      darkenColor(themeBgColor, 2.3)
    );
  }
}

function handleDarkMode() {
  const darkmode = getCSSVariable("--tg-color-scheme");
  if (darkmode == "dark") {
    document.body.classList.add("dark");
  } else {
    document.body.classList.remove("dark");
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyDOhp9S0csu3zNrqds-0VLX2kO1g2rI_Wc",
  authDomain: "defihub-fd6e3.firebaseapp.com",
  projectId: "defihub-fd6e3",
  storageBucket: "defihub-fd6e3.appspot.com",
  messagingSenderId: "195791504857",
  appId: "1:195791504857:web:0088c42fae02650a6e45c8",
  measurementId: "G-NXRFM5N748",
};
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const pinia = createPinia();
createApp(App)
  .use(UUID)
  .use(i18n)
  .use(pinia)
  .use(router)
  .use(SnackbarService)
  .use(VueLazyLoad, { loading: "", error: "" })
  .mount("#app");

requestAnimationFrame(() => {
  if (platform === "ios") {
    handleBrokenVariables();
  }
  if (platform === "android" || platform === "tdesktop") {
    handleDarkMode();
  }

  ready();
});
