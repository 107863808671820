import { seqno } from "./getDataV2";
export async function sleep(time: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}
export async function waitForSeqnoICO(address, seq = null) {
  let seqnoBefore;
  if (seq) {
    seqnoBefore = seq;
  } else {
    try {
      seqnoBefore = await seqno(address);
    } catch (ex) {
      setTimeout(async () => {
        seqnoBefore = await seqno(address);
      }, 1000);
    }
  }

  return async () => {
    // loading 180s
    for (let attempt = 0; attempt < 30; attempt++) {
      await sleep(5000);
      try {
        const seqnoAfter = await seqno(address);
        if (seqnoAfter > seqnoBefore) return true;
      } catch (ex) {
        await sleep(1000);
        const seqnoAfter = await seqno(address);
        if (seqnoAfter > seqnoBefore) return true;
      }
    }
    return false;
    // throw new Error("Timeout");
  };
}

export async function waitForSeqno(address: string) {
  const seqnoBefore = await seqno(address);

  return async () => {
    // loading 120s
    for (let attempt = 0; attempt < 40; attempt++) {
      await sleep(3000);
      const seqnoAfter = await seqno(address);

      if (seqnoAfter > seqnoBefore) return;
    }
    throw new Error("Timeout");
  };
}
