import { verifiedFetch } from "@helia/verified-fetch";
export async function imageIpfs(link) {
  const response = await verifiedFetch(link);
  const blob = await response.blob();
  return URL.createObjectURL(blob);
}

export async function jsonIpfs(link) {
  const resp = await verifiedFetch(link);
  const json = await resp.json();
  return json;
}
