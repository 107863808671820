//errorCodes trả về từ API
export enum errorCodes {
  success = 200,
  accepted = 202,
  badRequest = 400,
  unauthorize = 401,
  forbidden = 403,
  notFound = 404,
  methodNotAllow = 405,
  notAcceptable = 406,
  conflict = 409,
  limit = 429,
  internalServerError = 500,
  serviceUnavailable = 503,
}
