import { ICO } from "@/models/ICOModel";

export const progress = [
  {
    id: 1,
    name: "Lucky Mio",
    symbol: "LMI",
    subtitle: "",
    description:
      "Lucky Mio is a meme on ETH. This presale will cover LP and smart contract fees. You won't receive any $LMI tokens after the listing. Instead, you'll get a share of 2% of all the trading taxes collected on $LMI over the next 2 months.",
    startTime: "2024-06-14",
    endTime: "",
    endTimeUnix: 1719756000,
    startTimeUnix: 1718359601,
    cap: 0,
    participant: null,
    supply: 100000000000,
    forPreSale: 0,
    forLiquidity: 90000000000,
    liquidityPercent: 90,
    price: null,
    minBuy: 2,
    maxBuy: null,
    balance: 0,
    isAdmin: false,
    picture: "/pics/lmi.png",
    pictureThumb: "/pics/lmi.png",
    icon: "/token/lmi.png",
    website: "https://www.luckymio.xyz/",
    twitter: "https://x.com/LuckyMioMeow",
    telegram: "https://t.me/LuckyMioMeme",
    tokenAddress: "EQB_PFeQ1bJQt6noVVuHhmYcF0fiAX7ecF1lLURBiaZ5I4qh",
    contractAddress: "EQBIfXMEWdjamMTZkibRRFB-ggflDqq6udWvVRwXrGDUeOWQ",
    adminAddress: "UQBaxfH9yB364K5XuHBhuy0NAF_4OLCh2zJeBAvUfmkrXzS2",
    showContract: true,
    showTokenAddress: false,
    isDemo: false,
    bannerIsLight: true,
    isRefund: true,
    buyOnRefund: true,
    buyViaLink:
      "https://app.uniswap.org/explore/tokens/ethereum/0xefc3f1ecff8b9e9389323ef610bb9149236e62fd",
    airdropSlug: "lmi-distribute-ton",
    projectType: "RAISE",
    joinGas: 0.01,
    waitListId: 2,
  },
  {
    id: 2,
    name: "xBRC20",
    symbol: "xBRC20",
    subtitle: "",
    description:
      "Establish a Decentralized environment through the utilization of the Bitcoin index, enabling transparent transaction viewing and fluid exchanges between BRC20 and ERC20 tokens, supported by smart contracts, all within a secure network",
    startTime: "2024-04-26",
    endTime: "2024-10-01",
    endTimeUnix: 1727801999,
    startTimeUnix: 1717000920,
    cap: 1000,
    participant: 1000,
    supply: 21000000000,
    forPreSale: 0,
    forLiquidity: 0,
    liquidityPercent: 0,
    price: 0.0001,
    minBuy: 0.1,
    maxBuy: 5,
    balance: 0,
    isAdmin: false,
    picture: "/pics/xbrc.png",
    pictureThumb: "/pics/xbrc.png",
    icon: "/token/xbrc20.png",
    website: "https://xbrc20.io",
    twitter: "https://twitter.com/xbrc20_bridge",
    telegram: "https://t.me/xbrc20Dapp",
    tokenAddress: "EQDmXv553zczTQyguL364xwWPDfHjU_3t-zIF2e0-IxEk9-X",
    contractAddress: "EQArHuYyu1X7JYp64okX4uaO7zG5UEZXFgsWHLax2wXIgPIL",
    adminAddress: "EQDH8n5fWASRRSJRytCsKQsCV8MQB8n6SjPxuHmDTzuEIRrg",
    showContract: true,
    showTokenAddress: true,
    isDemo: true,
    bannerIsLight: false,
    isRefund: false,
    buyOnRefund: true,
    buyViaLink: "",
    airdropSlug: "",
    projectType: "DEFAULT",
    waitListId: null,
  },
] as Array<ICO>;

export const finished = [];
