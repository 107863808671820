export class NFTPutAuction {
  nftAddress: string;
  sellerAddress: string;
  reservePrice: number;
  buyoutPrice: number;
  allowBuyout: boolean;
  incrementPct: number;
  allowIncrementPct: boolean;
  endTime: string;
  constructor() {
    this.nftAddress = "";
    this.sellerAddress = "";
    this.reservePrice = 0;
    this.buyoutPrice = 0;
    this.allowBuyout = false;
    this.incrementPct = 0;
    this.allowIncrementPct = false;
    this.endTime = "";
  }
}
