export default [
  {
    path: "/profile",
    name: "profile",
    component: () => import("../gateway.vue"),
    meta: {
      isHeader: true,
      isFooter: false,

      pageName: "Profile",
    },
  },
  {
    path: "/user/:id/collection",
    name: "usercollection",
    component: () => import("@/views/nft/components/my.vue"),
    meta: {
      isHeader: true,
      isFooter: false,
      isMyNFT: true,
      pageName: "User NFT",
    },
  },
];
