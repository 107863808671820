export default [
  {
    path: "game",
    name: "game",
    component: () => import("@/views/game/index.vue"),
    meta: {
      isFooter: true,
      pageName: "Game",
    },
  },
  {
    path: "game/lottery",
    name: "gamelottery",
    component: () => import("@/views/game/page/lottery.vue"),
    meta: {
      isHeader: false,
      isFooter: false,
      pageName: "Game Lottery",
    },
  },
];
