import { isTelegramLink } from "@/utils/helpers";
import { uuid } from "vue-uuid";

class SocialsModel {
  link: string;
  type: string;
  constructor(m?: SocialsModel) {
    this.link = "";
    this.type = "";

    if (m) {
      this.link = m.link;
      this.type = m.type;
    }
  }
}
class TraitValue {
  value: string;
  name: string;
  selected: boolean;
  constructor() {
    this.value = "";
    this.name = "";
    this.selected = false;
  }
}
export class TraitsModel {
  traitType: string;
  values: Array<string>;
  data: Array<TraitValue>;
  id: string;
  constructor(m?: TraitsModel) {
    this.traitType = "";
    this.values = [];
    this.id = "";
    if (m) {
      this.id = uuid.v4();
      this.traitType = m.traitType;
      this.values = m.values;
      this.data = m.values
        ? m.values.reduce((arr: Array<TraitValue>, obj: string) => {
            const n = new TraitValue();
            n.name = obj;
            n.selected = false;
            n.value = obj.toLowerCase().replace(/\s/g, "");
            arr.push(n);
            return arr;
          }, [])
        : new Array<TraitValue>();
    }
  }
}
export class NFTCollection {
  id: number;
  name: string;
  owner: string;
  description: string;
  logo: string;
  banner: string;
  collectionUri: string;
  commonUri: string;
  royaltyPct: number;
  royaltyAddress: string;
  partnerId: number;
  minPrice: number;
  createdAt: string;
  picture: string;
  avatar: string;
  slug: string;
  socials: Array<string>;
  socialsData: Array<SocialsModel>;
  address: string;
  traits: Array<TraitsModel>;
  lastName: string;
  firstName: string;
  constructor(m?: NFTCollection) {
    this.id = 0;
    this.name = "";
    this.firstName = "";
    this.lastName = "";
    this.description = "";
    this.logo = "";
    this.banner = "";
    this.collectionUri = "";
    this.commonUri = "";
    this.royaltyAddress = "";
    this.royaltyPct = 0;
    this.partnerId = 0;
    this.minPrice = 0;
    this.createdAt = "";
    this.picture = "";
    this.avatar = "";
    this.slug = "";
    this.socials = new Array<string>();
    this.socialsData = new Array<SocialsModel>();
    this.traits = new Array<TraitsModel>();
    this.address = "";
    if (m) {
      this.id = m.id;
      this.name = m.name;
      this.description = m.description;
      this.logo = m.logo;
      this.banner = m.banner;
      this.collectionUri = m.collectionUri;
      this.commonUri = m.commonUri;
      this.createdAt = m.createdAt;
      this.minPrice = m.minPrice;
      this.partnerId = m.partnerId;
      this.royaltyAddress = m.royaltyAddress;
      this.royaltyPct = m.royaltyPct;
      this.picture = m.picture;
      this.avatar = m.avatar;
      this.slug = m.slug;
      this.socials = m.socials;
      this.socialsData = convertSocial(m.socials);
      this.address = m.address;
      this.traits = m.traits
        ? m.traits.map((x) => new TraitsModel(x))
        : new Array<TraitsModel>();
      if (m.name && m.name.length > 0) {
        const name = getFirstLast(m.name);
        this.lastName = name.last;
        this.firstName = name.first;
      }
    }
  }
}

function getFirstLast(name) {
  const words = name.split(" ");
  if (words.length >= 2) {
    const last = words.pop();
    return {
      first: words.join(" "),
      last: last,
    };
  } else {
    return {
      first: "",
      last: name,
    };
  }
}
function convertSocial(arr) {
  const res = new Array<SocialsModel>();
  arr.forEach((element) => {
    const m = new SocialsModel();
    m.link = element;
    if (isTelegramLink(element)) {
      m.type = "telegram";
      if (
        element.indexOf("play") > -1 ||
        element.indexOf("start") > -1 ||
        element.indexOf("startapp") > -1
      ) {
        m.type = "game";
      }
    } else if (element.indexOf("x.com") > -1) {
      m.type = "x";
    } else {
      m.type = "web";
    }
    res.push(m);
  });
  return res;
}
