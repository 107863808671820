import "core-js/modules/es.array.push.js";
import { useTelegram } from "@/hooks/useTelegram";
import { defineComponent, inject, ref, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { usePreferredColorScheme, onClickOutside } from "@vueuse/core";
import { useNftProcessStore } from "@/store";
import { getUnixNow } from "@/utils/date";
import { storeToRefs } from "pinia";
export default defineComponent({
  setup(props, {
    emit
  }) {
    const showDropdown = ref(false);
    const targetWallet = ref(null);
    onClickOutside(targetWallet, event => showDropdown.value = false);
    const colorScheme = usePreferredColorScheme();
    const router = useRouter();
    const route = useRoute();
    const pageName = computed(() => route.meta.pageName);
    const {
      vibrate
    } = useTelegram();
    const friendlyAddress = inject("friendlyAddress", ref(""));
    const showAddress = computed(() => {
      if (friendlyAddress.value) {
        const str = friendlyAddress.value;
        return str.substring(str.length - 4);
      }
      return "";
    });
    const toggleDropdown = () => {
      if (friendlyAddress.value.length > 0) {
        vibrate("medium");
        showDropdown.value = !showDropdown.value;
      } else {
        emit("connect");
      }
    };
    const onDisconnect = () => {
      vibrate("medium");
      emit("disconnect");
      showDropdown.value = false;
    };
    const onGoHome = () => {
      vibrate("medium");
      const name = route.name;
      switch (name) {
        case "collectioninfo":
        case "nftinfo":
        case "usercollection":
          {
            router.push({
              name: "nft"
            });
          }
          break;
        case "icodetail":
        case "icoairdrop":
          {
            router.push({
              name: "ico"
            });
          }
          break;
        default:
          router.push("/");
          break;
      }
    };
    const isMenuNFT = ref(false);
    watch(() => router.currentRoute.value, () => {
      const nft = router.currentRoute.value.meta.isMyNFT;
      isMenuNFT.value = nft && nft == true;
    }, {
      immediate: true
    });
    const owner = inject("friendlyAddress", ref(""));
    const onOpenNFT = () => {
      vibrate("medium");
      router.push({
        name: "usercollection",
        params: {
          id: owner.value
        }
      });
      showDropdown.value = false;
    };
    const onOpenPotfolio = () => {
      vibrate("medium");
      router.push({
        name: "portfolio"
      });
      showDropdown.value = false;
    };
    // user store pinia
    const useProcess = useNftProcessStore();
    // get data from localstorage
    useProcess.getLocal();
    const {
      nfts
    } = storeToRefs(useProcess);
    const isProcess = computed(() => {
      const current = getUnixNow();
      const list = nfts.value.filter(f => f.expired > current);
      if (list.length > 0) {
        return true;
      } else return false;
    });
    return {
      targetWallet,
      colorScheme,
      toggleDropdown,
      showDropdown,
      showAddress,
      onDisconnect,
      onGoHome,
      pageName,
      isMenuNFT,
      owner,
      onOpenNFT,
      onOpenPotfolio,
      isProcess
    };
  }
});