import { Address, Dictionary, fromNano } from "@ton/core";
import { getClientV4 } from "./useClientV4";
import { makeGetCallV2 } from "./make-get-call-v2";
import { _parseGetMethodCall } from "./make-get-call";

import { decodeContentCollect, decodeContentNFT } from "./nftContent";

export async function getNFTData(contract: string) {
  //nft contract
  const contractAddr = Address.parse(contract);
  const tc = await getClientV4();
  const stack = await makeGetCallV2(contractAddr, "get_nft_data", [], tc);

  const nft = {
    init: stack.readNumber(),
    index: stack.readNumber(),
    collection: stack.readAddress(),
    owner: stack.readAddress().toString({ bounceable: false }),
    content: stack.readCell(),
  };

  // get full data
  const stackcontent = await makeGetCallV2(
    nft.collection,
    "get_nft_content",
    [
      { type: "int", value: BigInt(nft.index) },
      { type: "cell", cell: nft.content },
    ],
    tc
  );
  const dataCell = stackcontent.readCell();
  const json = await decodeContentNFT(nft, dataCell);
  return json;
}

// get collect
export async function getCollectionData(colletion: string) {
  const contractAddr = Address.parse(colletion);
  const tc = await getClientV4();
  const stack = await makeGetCallV2(
    contractAddr,
    "get_collection_data",
    [],
    tc
  );

  const index = stack.readNumber();
  const content = stack.readCell();
  const owner = stack.readAddress().toString();
  try {
    const json = await decodeContentCollect(content);

    return {
      index: index,
      content: json,
      owner: owner,
    };
  } catch (ex) {
    return {
      index: index,
      content: {},
      owner: owner,
    };
  }
}

// get nft by index
export async function getNFTByIndex(contract: string, index: number) {
  const contractAddr = Address.parse(contract);
  const tc = await getClientV4();
  const stack = await makeGetCallV2(
    contractAddr,
    "get_nft_address_by_index",
    [{ type: "int", value: index }],
    tc
  );

  const data = {
    address: stack.readAddress().toString(),
  };
  console.log("nft", data);

  return data;
}

export async function getNFTSale(address: string) {
  const contractAddr = Address.parse(address);
  const tc = await getClientV4();
  const stack = await makeGetCallV2(contractAddr, "get_sale_data", [], tc);

  const data = {
    magic: stack.readNumber(),
    is_complete: stack.readBoolean(),
    created_at: stack.readNumber(),
    marketplace: stack.readAddress().toString(),
    nft_address: stack.readAddress().toString(),
    owner_address: stack.readAddress().toString(),
    full_price: Number(fromNano(stack.readBigNumber())),
    market_fee_address: stack.readAddress().toString(),
    market_fee: Number(fromNano(stack.readBigNumber())),
    royalty_address: stack.readAddress().toString(),
    royalty_amount: Number(fromNano(stack.readBigNumber())),
  };

  return data;
}

// contract defihub
export async function getConfig(address: string) {
  const contractAddr = Address.parse(address);
  const tc = await getClientV4();
  const stack = await makeGetCallV2(contractAddr, "get_config", [], tc);
  const data = {
    id: Number(fromNano(stack.readBigNumber())),
    nftItem: stack.readAddress().toString(), //  nft item
    seller: stack.readAddress().toString(), // seller
    price: Number(fromNano(stack.readBigNumber())), // price
    minPrice: Number(fromNano(stack.readBigNumber())), // validate chang price
    feeTo: stack.readAddress().toString(), // address dev  fee
    feeToPct: Number(stack.readBigNumber()), // % fee dev
    royaltyTo: stack.readAddress().toString(), // address fee royalty
    royaltyPct: Number(fromNano(stack.readBigNumber())), // % fee royalty
  };

  return data;
}

export async function getProgress(address: string) {
  const contractAddr = Address.parse(address);
  const tc = await getClientV4();
  const stack = await makeGetCallV2(contractAddr, "get_progress", [], tc);

  const data = {
    isCompleted: stack.readBoolean(), //isCompleted == true not allow buy, cancel
    createdAt: stack.readNumber(), //
    soldAt: stack.readNumber(), //
  };

  return data;
}

export async function getSaleAddress(address: string, index: number) {
  const contractAddr = Address.parse(address);
  const tc = await getClientV4();
  const stack = await makeGetCallV2(
    contractAddr,
    "get_sale_address",
    [{ type: "int", value: BigInt(index) }],
    tc
  );

  const data = {
    address: stack.readAddress().toString({ bounceable: true }),
  };

  return data;
}
// status auction
export async function getStatus(address: string) {
  const contractAddr = Address.parse(address);
  const tc = await getClientV4();
  const stack = await makeGetCallV2(contractAddr, "get_status", [], tc);

  return stack.readNumber();
}
