import axios from "@/services/axiosApiConfig";
import { Result } from "@/models/Result";
import { errorCodes } from "@/utils/errorCode";

import { useAlertStore } from "@/store";

export function apiCaller(
  method,
  apiPath: string,
  jsonData: any,
  headers: any = {},
  showErrorNotify: boolean = true
) {
  const useAlert = useAlertStore();

  const axiosConfig = {
    method: method,
    data: jsonData,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
  };

  return axios(apiPath, axiosConfig)
    .then(async (response) => {
      const data = response.data as Result;

      switch (data.statusCode) {
        case errorCodes.accepted:
        case errorCodes.success:
          break;
        case errorCodes.internalServerError:
          if (showErrorNotify) {
            try {
              useAlert.$patch({
                alert: true,
                message: "Error 500",
                type: "error",
              });
            } catch (ex) {
              //
            }
          }
          break;
        default:
          if (showErrorNotify) {
            try {
              useAlert.$patch({
                alert: true,
                message: data.message,
                type: "error",
              });
            } catch (ex) {
              //
            }
          }
          break;
      }
      return data;
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const result = error.response.data as Result;

        if (showErrorNotify) {
          if (error.response.status == 500) {
            try {
              useAlert.$patch({
                alert: true,
                message: "Error 500",
                type: "error",
              });
            } catch (ex) {
              //
            }
          } else {
            try {
              useAlert.$patch({
                alert: true,
                message: result.message
                  ? result.message
                  : error.response.data.message,
                type: "error",
              });
            } catch (ex) {
              //
            }
          }
          return null;
        } else {
          return result;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        if (!error.response) {
          notifyNetworkError(error.message);
        } else {
          try {
            useAlert.$patch({
              alert: true,
              message: error.request.toString(),
              type: "error",
            });
          } catch (ex) {
            //
          }
        }
        return null;
      } else {
        // Something happened in setting up the request that triggered an Error

        try {
          useAlert.$patch({
            alert: true,
            message: error.message,
            type: "error",
          });
        } catch (ex) {
          //
        }
        return null;
      }
    });
}

/**
 * Gom thông báo network error gửi 1 lần thay vì mỗi request mỗi notify
 */
let timeToNotifiy = 1;
let timeoutNoitfy = null as any;
function notifyNetworkError(message) {
  const useAlert = useAlertStore();
  if (timeoutNoitfy == null) {
    timeoutNoitfy = setInterval(() => {
      if (timeToNotifiy > 0) timeToNotifiy -= 1;
      if (timeToNotifiy == 0) {
        try {
          useAlert.$patch({
            alert: true,
            message: message,
            type: "error",
          });
        } catch (ex) {
          //
        }
        timeToNotifiy = 1;
        clearInterval(timeoutNoitfy);
        timeoutNoitfy = null;
      }
    }, 1000);
  }
}
