import { TonClient } from "@ton/ton";
import { getHttpEndpoint } from "@orbs-network/ton-access";
import { PARAMSV2 } from "../constantsV2";

const endpointPV2 = getHttpEndpoint({
  network: "mainnet",
});

async function _getClientV2() {
  const arr = [PARAMSV2.apiKey, PARAMSV2.apiKeyBE];
  const key = arr[Math.floor(Math.random() * arr.length)];
  return new TonClient({
    endpoint: PARAMSV2.apiUrl,
    apiKey: key,
  });
}

const clientPV2 = _getClientV2();

export async function getClientV2() {
  return clientPV2;
}

export async function getEndpointV2() {
  return endpointPV2;
}

// get the decentralized RPC endpoint
const endpointV3 = getHttpEndpoint({
  network: "mainnet",
});
export async function getEndpointV3() {
  return endpointV3;
}

export async function _getClientV3() {
  return new TonClient({
    endpoint: await getEndpointV3(),
  });
}

const clientV3 = _getClientV3();
export async function getClientV3() {
  return clientV3;
}
