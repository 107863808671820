import { TonConnectError } from "@tonconnect/sdk";
/**
 * In memory storage like localStorage, but without persistence.
 * Uses as a fallback for localStorage in Safari's private mode.
 */
class InMemoryStorage {
  constructor() {
    this.storage = {};
  }
  static getInstance() {
    if (!InMemoryStorage.instance) {
      InMemoryStorage.instance = new InMemoryStorage();
    }
    return InMemoryStorage.instance;
  }
  get length() {
    return Object.keys(this.storage).length;
  }
  clear() {
    this.storage = {};
  }
  getItem(key) {
    var _a;
    return (_a = this.storage[key]) !== null && _a !== void 0 ? _a : null;
  }
  key(index) {
    var _a;
    const keys = Object.keys(this.storage);
    if (index < 0 || index >= keys.length) {
      return null;
    }
    return (_a = keys[index]) !== null && _a !== void 0 ? _a : null;
  }
  removeItem(key) {
    delete this.storage[key];
  }
  setItem(key, value) {
    this.storage[key] = value;
  }
}

export class DefaultStorage {
  constructor() {
    this.localStorage = tryGetLocalStorage();
  }
  getItem(key) {
    return __awaiter(this, void 0, void 0, function* () {
      return this.localStorage.getItem(key);
    });
  }
  removeItem(key) {
    return __awaiter(this, void 0, void 0, function* () {
      this.localStorage.removeItem(key);
    });
  }
  setItem(key, value) {
    return __awaiter(this, void 0, void 0, function* () {
      this.localStorage.setItem(key, value);
    });
  }
}

function __awaiter(thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P
      ? value
      : new P(function (resolve) {
          resolve(value);
        });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done
        ? resolve(result.value)
        : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
}

/**
 * Returns `localStorage` if it is available. In Safari's private mode, it returns `InMemoryStorage`. In Node.js, it throws an error.
 */
function tryGetLocalStorage() {
  if (isLocalStorageAvailable()) {
    return localStorage;
  }
  if (isNodeJs()) {
    throw new TonConnectError(
      "`localStorage` is unavailable, but it is required for TonConnect. For more details, see https://github.com/ton-connect/sdk/tree/main/packages/sdk#init-connector"
    );
  }
  return InMemoryStorage.getInstance();
}
/**
 * Checks if `localStorage` is available.
 */
function isLocalStorageAvailable() {
  // We use a try/catch block because Safari's private mode throws an error when attempting to access localStorage.
  try {
    return typeof localStorage !== "undefined";
  } catch (_a) {
    return false;
  }
}
/**
 * Checks if the environment is Node.js.
 */
function isNodeJs() {
  return (
    typeof process !== "undefined" &&
    process.versions != null &&
    process.versions.node != null
  );
}
