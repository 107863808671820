import collectInfo from "@/views/nft/components/collectionInfo.vue";
import nftInfo from "@/views/nft/components/nftInfo.vue";
import collection from "@/views/nft/components/collections.vue";
export default [
  {
    path: "collection",
    name: "nft",
    component: () => import("@/views/nft/index.vue"),
    meta: {
      isFooter: true,
      isMyNFT: true,
      pageName: "NFT",
    },
  },

  {
    path: "marketplace",
    name: "marketplace",
    component: () => import("@/views/nft/marketplace.vue"),
    meta: {
      isFooter: false,
      isHeader: false,
      isMyNFT: false,
      pageName: "Marketplace",
    },
  },
  {
    path: "collection/:id",
    name: "nftcollection",
    component: collection,
    meta: {
      isHeader: true,
      isFooter: false,
      isMyNFT: true,
      pageName: "Collections",
    },
    children: [
      {
        path: "info",
        name: "collectioninfo",
        component: collectInfo,
        meta: {
          isHeader: true,
          isFooter: false,
          isMyNFT: true,
          pageName: "Collection Info",
        },
      },
    ],
  },
  {
    path: "collection/:id/nft/:index",
    name: "nftinfo",
    components: {
      default: collectInfo,
    },

    meta: {
      nftmodal: true,
      isHeader: true,
      isFooter: false,
      isMyNFT: true,
      pageName: "NFT Info",
    },
    beforeEnter: (to: any, from: any, next: any) => {
      const twModalView = from.matched.some(
        (view: any) => view.meta && view.meta.twModalView
      );

      if (!twModalView) {
        // nếu refresh link task thì không show project
        to.matched[1].components = {
          default: collection,
          modal: nftInfo,
        };
      }
      if (twModalView) {
        if (from.matched.length > 1) {
          // copy nested router
          const childrenView = from.matched.slice(2, from.matched.length);
          for (const view of childrenView) {
            if (
              to.matched.filter((s: any) => s.name == view.name).length == 0
            ) {
              to.matched.push(view);
            }
          }
        }

        if (to.matched[1].components) {
          // Rewrite components for `default`
          if (from.matched.length > 0) {
            to.matched[1].components.default =
              from.matched[1].components.default;
            // Rewrite components for `modal`
            to.matched[1].components.modal = nftInfo;
          }
        }
      }
      next();
    },
  },
];
